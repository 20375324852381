<svelte:options
  customElement={{
    tag: "oc-snackbar-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      open: { type: "Boolean" },
      message: { type: "String" },
      id: { type: "String", reflect: true },
      autoDismiss: { type: "Boolean", attribute: "auto-dismiss" },
      forceReplaceExistingInstance: {
        type: "Boolean",
        attribute: "force-replace-existing-instance",
      },
    },
  }}
/>

<script lang="ts">
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { srSpeak } from "@otto-ec/global-resources/accesibility";
  import { onDestroy } from "svelte";
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import type { Events, Props } from "./SnackbarV1.types.js";
  import { safeId } from "@otto-ec/otto-components-utils/utils/safeId";

  let {
    open = false,
    message,
    id = safeId(),
    autoDismiss = true,
    forceReplaceExistingInstance = false,
  }: Props = $props();

  const Host = $host();
  const slots = useSlots(Host);
  const dispatch = useEventDispatcher<Events>(Host);

  let isOpen = $state(open);
  let duration = 5000;
  let isHovered = $state(false);

  function handleClose(): void {
    open = false;
  }

  function handleESCKey(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      handleClose();
    }
  }

  $effect(() => {
    if (open === isOpen) return;

    /*                                             */
    /*                                                     */
    if (!dispatch(open ? "oc-open" : "oc-close")) {
      open = isOpen;
      return;
    }

    isOpen = open;
  });

  $effect(() => {
    let timeOut: number | undefined;
    const clear = (): void => window.clearTimeout(timeOut);

    /*                                                    */
    if (!open || !autoDismiss) return clear();

    /*                                  */
    srSpeak(message, { type: "assertive" });

    /*                                                          */
    if (isHovered) return clear();

    /*                                                   */
    timeOut = window.setTimeout(() => {
      open = false;
    }, duration);

    /*                                  */
    return clear;
  });

  /*                                                                                      */
  /*                                         */
  /*                                                                       */
  if (forceReplaceExistingInstance) {
    document.querySelectorAll(`[id="${id}"]`).forEach((el) => el.remove());
  }

  /*                                                                                              */
  /*                                                                                                       */
  if (import.meta.env.STORYBOOK) {
    /*                                                                                    */
    onDestroy(handleClose);
  }
</script>

<svelte:document onkeydown={handleESCKey} />

<div
  role="status"
  class="snackbar"
  class:snackbar--open={isOpen}
  class:snackbar--has-actions={slots.actions}
  onmouseenter={() => (isHovered = true)}
  onmouseleave={() => (isHovered = false)}
>
  <div class="snackbar__body">
    {message}
  </div>

  {#if slots.actions}
    <div class="snackbar__actions" style:display={isOpen ? "block" : "none"}>
      <slot name="actions" />
    </div>
  {/if}

  <!-- svelte-ignore a11y_no_static_element_interactions -->
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <oc-icon-button-v2
    class="snackbar__close-button"
    style:display={isOpen ? "block" : "none"}
    variant="inverted-transparent"
    icon="close"
    size={/*                      */ "50"}
    onclick={handleClose}
    ocAriaLabel="Hinweis schließen"
    title="Hinweis schließen"
  ></oc-icon-button-v2>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $icon-button-width: calc(tokens.$oc-base-dimension-relative-32 + 16px);

  .snackbar {
    align-items: center;
    background-color: tokens.$oc-component-snackbar-background-color;
    border-radius: tokens.$oc-component-snackbar-border-radius;
    bottom: tokens.$oc-component-snackbar-outer-spacing-bottom;
    display: flex;
    filter: tokens.$oc-component-snackbar-shadow;
    flex-wrap: wrap;
    gap: tokens.$oc-component-snackbar-text-action-gap-y
      tokens.$oc-component-snackbar-text-action-min-gap-x;
    left: 50%;
    min-height: 2rem;
    padding-bottom: tokens.$oc-component-snackbar-spacing-y;
    padding-left: tokens.$oc-component-snackbar-spacing-left;
    padding-right: $icon-button-width;
    padding-top: tokens.$oc-component-snackbar-spacing-y;
    position: fixed;
    width: calc(
      100vw - tokens.$oc-component-snackbar-spacing-left -
        tokens.$oc-component-snackbar-outer-spacing-x * 2 - tokens.$oc-component-snackbar-gap-x -
        $icon-button-width
    );
    z-index: 9999;

    &__body {
      color: tokens.$oc-component-snackbar-copy-color;
      font: tokens.$oc-component-snackbar-copy-font;
      line-height: tokens.$oc-base-font-line-height-150;
      padding-right: calc(tokens.$oc-component-snackbar-gap-x / 2);
    }

    &__actions {
      padding-right: calc(tokens.$oc-component-snackbar-gap-x / 2);
    }

    &__close-button {
      position: absolute;
      top: tokens.$oc-semantic-spacing-50;
      right: tokens.$oc-semantic-spacing-50;
      padding: 0;
      background: none;
      border: none;
    }

    &--has-actions {
      .snackbar__body {
        padding-right: 0;
      }
    }
  }

  .snackbar {
    transition: all tokens.$oc-component-snackbar-disappear-duration
      tokens.$oc-component-snackbar-disappear-easing;
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  .snackbar--open {
    transition: all tokens.$oc-component-snackbar-appear-duration
      tokens.$oc-component-snackbar-appear-easing;
    opacity: 1;
    transform: translate(-50%, 0);
  }

  @include mixins.breakpoint-from-m(false, false) {
    .snackbar {
      max-width: max-content;
    }
  }
</style>

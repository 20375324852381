<svelte:options
  customElement={{
    tag: "oc-sheet-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      open: { type: "Boolean" },
      headline: { type: "String" },
      id: { type: "String", reflect: true },
      url: { type: "String" },
      base64Url: { type: "String", attribute: "base64-url" },
      hideHeader: { type: "Boolean", attribute: "hide-header" },
      hideCloseButton: { type: "Boolean", attribute: "hide-close-button" },
      fullHeight: { type: "Boolean", attribute: "full-height" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      noContentPadding: { type: "Boolean", attribute: "no-content-padding" },
      allowedErrorStatusCodes: { type: "Array", attribute: "allowed-error-status-codes" },
      forceReplaceExistingInstance: {
        type: "Boolean",
        attribute: "force-replace-existing-instance",
      },
    },
  }}
/>

<script lang="ts" module>
  import { onDestroy } from "svelte";
  import type { Methods, Props } from "./SheetV1.types";
  import { useSlots } from "../../../common/utils/useSlots.svelte";

  import { useSheetEvents } from "./lib/events.svelte";
  import * as getters from "./lib/getters.js";

  import { useDeviceProperties, useDocumentScrollBlock } from "./lib/document.js";
  import { onBackdropClick, onCloseButtonClick, useSheetVisibility } from "./lib/visibility.js";

  import {
    back as goBack,
    close as closeSheet,
    next as nextSheet,
    isSheetOpen,
    isSwitching,
    noTransition,
    openSheetID,
    previousSheetID,
    requestedClose,
    useSheetHistory,
    sheetHistory,
    initHistoryStack,
    openSheetProps,
  } from "./lib/sheetHistory";

  import { sheetFocusTrap } from "./lib/focus.svelte";
  import { useSheetTouchScroll } from "./lib/touch";
  import { sheetProps } from "./lib/utils.js";
  import { useWindowHistory } from "./lib/windowHistory";
  import { create as createSheet } from "./index.js";
  import { useExternalContent } from "./lib/externalContent.svelte";
  import { useTrackingContext } from "./lib/tracking.svelte";
  import { safeId } from "@otto-ec/otto-components-utils/utils/safeId";

  useDocumentScrollBlock(isSheetOpen);
  useWindowHistory(
    sheetHistory,
    openSheetProps,
    initHistoryStack,
    createSheet,
    nextSheet,
    goBack,
    closeSheet,
  );

  const { isTouchableDevice } = useDeviceProperties();
</script>

<script lang="ts">
  let {
    open = false as boolean | undefined,
    headline,
    id = safeId(),
    url,
    base64Url,
    hideHeader = false,
    hideCloseButton = false,
    hideBackButton = false,
    fullHeight = false,
    ocAriaLabel,
    noContentPadding = false,
    allowedErrorStatusCodes = [],
    extraData = null,
    /*                                     */
    forbiddenExternalProps = sheetProps.slice(0, 7),
    forceReplaceExistingInstance = false,
  }: Props = $props();

  const Host = $host<HTMLOcSheetV1Element>();

  let closeButtonElement = $state<HTMLOcIconButtonV2Element>();
  let backButtonElement = $state<HTMLOcIconButtonV2Element>();

  const slots = useSlots(Host);

  const { syncInstanceOpenState, isActiveSheet } = useSheetHistory(Host);

  const {
    hostElementVisible,
    sheetElementVisible,
    sheetElementFullyOpened,
    computeAriaLabelByHeadlineSlot,
  } = useSheetVisibility(Host, isActiveSheet);

  /*                          */
  const {
    isDragging,
    contentHeight,
    isScrolling,
    isScrollEnd,
    sheetDragStyle,
    hasContentOverflow,
    contentElementWatcher,
    touchHeaderEvents,
    touchContentEvents,
  } = useSheetTouchScroll();

  /*                                    */
  /*                                   */
  const externalContentUrl = $derived(base64Url ? atob(base64Url) : url);

  const externalContent = useExternalContent(Host);
  $effect(() => {
    externalContent.url = externalContentUrl;
    externalContent.isActive = $isActiveSheet;
    externalContent.allowedCodes = allowedErrorStatusCodes;
    externalContent.forbiddenProps = forbiddenExternalProps;
  });

  const tracking = useTrackingContext(externalContent);
  $effect(() => {
    tracking.sheetId = id;
    tracking.isActive = $isActiveSheet;
  });

  useSheetEvents(
    Host,
    hostElementVisible,
    sheetElementVisible,
    sheetElementFullyOpened,
    isActiveSheet,
    isSwitching,
    requestedClose,
    extraData,
    externalContent,
  );

  /*                                                            */
  /*                                                                       */
  $effect(() => {
    open = syncInstanceOpenState(open, id, $openSheetID);
  });

  /*                                                      */
  $effect(() => {
    if ((externalContentUrl && externalContent.isApplied) || $sheetElementVisible)
      ocAriaLabel = computeAriaLabelByHeadlineSlot(ocAriaLabel, headline);
  });

  /*                                                                 */
  /*                                            */
  const hasBackButton = $derived(!!$previousSheetID && !hideBackButton);

  /*                                                                                   */
  /*                                         */
  /*                                                                    */
  if (forceReplaceExistingInstance) {
    document.querySelectorAll(`[id="${id}"]`).forEach((el) => el.remove());
  }

  /*                                                                                           */
  /*                                                                                                    */
  if (import.meta.env.STORYBOOK) {
    /*                                                                                    */
    onDestroy(closeSheet);
  } else {
    document.body.append(Host);
  }

  /*                                                                  */
  /*                                                                     */
  export const back: Methods["back"] = goBack;
  export const close: Methods["close"] = closeSheet;
  export const getContent: Methods["getContent"] = getters.getContent.bind(null, Host);
  export const getHeader: Methods["getHeader"] = getters.getHeader.bind(null, Host);
  export const getActions: Methods["getActions"] = getters.getActions.bind(null, Host);
</script>

<div
  class="sheet"
  class:sheet--open={$sheetElementVisible}
  class:sheet--no-transition={$noTransition}
  class:sheet--no-header={hideHeader}
  class:sheet--full-height={fullHeight}
  class:sheet--is-touchable={$isTouchableDevice}
  class:sheet--no-content-padding={noContentPadding}
>
  <!-- Focus trap start: triggers .sheet__sheet:not(:focus-within) on focus  -->
  <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
  <span class="sheet__focus-trap" tabindex="0" inert></span>
  <div class="sheet__backdrop" onclick={onBackdropClick} aria-hidden="true"></div>
  <div
    class="sheet__sheet"
    class:sheet__sheet--loaded={externalContent.isApplied}
    style={$sheetDragStyle}
    role="dialog"
    aria-label={ocAriaLabel}
    aria-describedby="dialog-headline"
    aria-modal="true"
    tabindex="-1"
  >
    <!--SHEET HEADER-->
    <div
      class="sheet__header"
      class:sheet__header--with-shadow={$isScrolling && !hideHeader}
      use:touchHeaderEvents
    >
      <!-- svelte-ignore a11y_click_events_have_key_events -->
      <!-- svelte-ignore a11y_no_static_element_interactions -->
      <oc-icon-button-v2
        style:display={hasBackButton ? "" : "none"}
        bind:this={backButtonElement}
        onclick={back}
        icon="arrow-left"
        elevation={hideHeader ? "200" : "0"}
        class="sheet__back-button"
        ocAriaLabel="zurück"
      ></oc-icon-button-v2>

      <div class="sheet__headline-outer">
        <slot name="headline">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {#if headline}<h3 class="sheet__headline" id="dialog-headline">{@html headline}</h3>{/if}
        </slot>
      </div>
      <!-- svelte-ignore a11y_click_events_have_key_events -->
      <!-- svelte-ignore a11y_no_static_element_interactions -->
      <oc-icon-button-v2
        bind:this={closeButtonElement}
        onclick={onCloseButtonClick}
        class:sheet__close-button--hidden={hideCloseButton}
        icon="close"
        elevation={hideHeader ? "200" : "0"}
        class="sheet__close-button"
        ocAriaLabel="schließen"
      ></oc-icon-button-v2>
    </div>
    <!--SHEET CONTENT-->
    <div
      class="sheet__content"
      class:sheet__content--no-scrolling={$isDragging}
      use:contentElementWatcher
      use:touchContentEvents
      bind:clientHeight={$contentHeight}
    >
      {#if !externalContent.rawError || externalContent.errorContent}
        <!--CONTENT SLOT-->
        <slot />
      {:else}
        <!--LOADING-ERROR SLOT-->
        <div class="sheet_loading-error">
          <slot name="loading-error"></slot>
        </div>
      {/if}
    </div>

    <!--SHEET FOOTER-->
    {#if slots.actions}
      <div
        class="sheet__footer"
        class:sheet__footer--with-shadow={$hasContentOverflow && !$isScrollEnd}
      >
        <div class="sheet__actions">
          <slot name="actions" />
        </div>
      </div>
    {/if}

    <span
      use:sheetFocusTrap={{
        backButtonElement,
        closeButtonElement,
        hasBackButton,
        sheetElementVisible: $sheetElementVisible,
      }}
      class="sheet__focus-helper"
    ></span>
  </div>
  <!-- Focus trap end: triggers .sheet__sheet:not(:focus-within) on focus -->
  <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
  <span class="sheet__focus-trap" tabindex="0" inert></span>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  ::slotted([slot="headline"]) {
    /*                                    */
    margin-top: 4px !important;
    margin-bottom: 16px !important;
  }

  .sheet {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9001;
    overscroll-behavior-y: none;

    &__backdrop {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: tokens.$oc-component-sheet-scrim-color;
      opacity: 0;
      transition: opacity ease 0.3s;
    }

    &__sheet {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      top: initial;
      max-height: 80vh;
      max-height: 80dvh;
      display: flex;
      flex-direction: column;
      background: tokens.$oc-component-sheet-content-default-background-color;
      transform: translateY(100%);
      transition: all tokens.$oc-component-sheet-show-duration
        tokens.$oc-component-sheet-show-easing;
      border-radius: tokens.$oc-component-sheet-border-radius
        tokens.$oc-component-sheet-border-radius 0 0;
      overflow: auto;

      &:focus {
        outline: none;
      }
    }

    &__header {
      position: relative;
      z-index: 1;
      display: flex;
      gap: tokens.$oc-component-sheet-header-gap-x;
      align-items: flex-start;
      padding: 20px 12px 0 16px;
      min-height: 44px; /*                                                  */
      flex-shrink: 0;
      background: tokens.$oc-component-sheet-header-background-color;
      border-bottom: tokens.$oc-component-sheet-header-conditional-border-bottom-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        border-color: tokens.$oc-component-sheet-header-conditional-border-bottom-color;
      }
    }

    &__headline-outer {
      flex: 1 0;
    }

    &__headline {
      flex: 1 1 auto;
      color: tokens.$oc-component-sheet-header-title-color;
      font: tokens.$oc-component-sheet-header-title-font;
      /*                                                                                */
      margin-top: 4px;
      margin-bottom: tokens.$oc-semantic-spacing-100;
      user-select: none;
    }

    &__back-button {
      margin-left: -4px;
    }

    &__close-button {
      &--hidden {
        /*                                 */
        width: 0;
        height: 0;
        display: block;
        overflow: hidden;
      }
    }

    &__content {
      flex-grow: 0;
      overflow: auto;
      overscroll-behavior: contain;

      transform: translateY(0); /*                   */
      min-height: 72px; /*                                                                     */
      padding: tokens.$oc-semantic-spacing-50 tokens.$oc-semantic-spacing-100
        tokens.$oc-semantic-spacing-100;

      &:focus {
        outline-offset: -2px !important;
        & {
          @include mixins.focus-styles(tokens.$oc-component-button-50-border-radius);
        }
      }

      &--no-scrolling {
        overflow: hidden;
      }
    }

    &__footer {
      padding: tokens.$oc-semantic-spacing-100 tokens.$oc-semantic-spacing-100 30px
        tokens.$oc-semantic-spacing-100;
      border-top: tokens.$oc-component-sheet-action-bar-conditional-border-top-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        background-color: tokens.$oc-component-sheet-action-bar-background-color;
        border-color: tokens.$oc-component-sheet-action-bar-conditional-border-top-color;
      }

      slot {
        filter: none;
      }
    }

    &__actions {
      filter: none;
    }

    &__focus-trap {
      /*                                 */
      width: 0;
      height: 0;
      display: block;
      overflow: hidden;
    }

    &__focus-helper {
      /*                                  */
      width: 0;
      height: 0;
      display: block;
      opacity: 0;
    }
  }

  /*        */
  .sheet--open {
    inset: 0;

    .sheet__sheet {
      transform: translateY(0);

      /*                                   */
      /*                                                                                                      */
      /*                                                                        */
      &:not(:focus-within) .sheet__focus-helper {
        transition: opacity 0.01s;
        opacity: 0.1;
      }
    }

    .sheet__backdrop {
      left: 0;
      top: 0;
      opacity: 0.75;
    }
  }

  .sheet--no-transition {
    .sheet__backdrop {
      transition: none;
    }
  }

  .sheet--no-header {
    .sheet__header {
      background: none;
    }

    .sheet__headline {
      display: none;
    }

    .sheet__back-button {
      margin-top: 0;
    }

    .sheet__content {
      margin-top: -65px; /*                       */
      padding-top: tokens.$oc-semantic-spacing-100;
    }
  }

  .sheet--full-height {
    .sheet__sheet {
      height: 80vh;
      height: 80dvh;
    }
    .sheet__content {
      flex-grow: 1;
    }
  }

  .sheet--full-height.sheet--no-transition {
    .sheet__sheet {
      transition: none;
    }
  }

  .sheet--is-touchable {
    .sheet__header::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: tokens.$oc-component-sheet-drag-indicator-spacing-top;
      width: tokens.$oc-component-sheet-drag-indicator-width;
      height: tokens.$oc-component-sheet-drag-indicator-height;
      border-radius: tokens.$oc-component-sheet-drag-indicator-border-radius;
      background-color: tokens.$oc-component-sheet-drag-indicator-color;
    }
  }

  .sheet--no-content-padding {
    .sheet__content {
      padding: 0;
    }
  }

  /*                                    */
  /*                                                                            */
  @media (min-width: 480px) {
    .sheet {
      &__sheet {
        top: 0;
        left: initial;
        width: 448px;
        max-height: none;
        transform: translateX(100%);
        border-radius: tokens.$oc-component-sheet-border-radius 0 0
          tokens.$oc-component-sheet-border-radius;
      }
    }

    .sheet--open {
      .sheet__sheet {
        /*                                                                                                 */
        /*                                      */
        transform: translateX(0) !important;
      }

      .sheet__header {
        /*                                                                     */
        pointer-events: none;
      }

      .sheet__back-button,
      .sheet__close-button {
        /*                                               */
        pointer-events: all;
      }
    }
    .sheet--no-transition {
      .sheet__sheet {
        transition: none;
      }
    }
    .sheet--full-height {
      .sheet__sheet {
        height: auto;
      }
    }
    .sheet--is-touchable {
      .sheet__header::after {
        content: none;
      }
    }
  }
</style>
